export const locationSubtypes = [
  {
    name: "ACCOUNTING"
  },
  {
    name: "AIRPORT"
  },
  {
    name: "AMUSEMENT_PARK"
  },
  {
    name: "AQUARIUM"
  },
  {
    name: "ART_GALLERY"
  },
  {
    name: "ATM"
  },
  {
    name: "BAKERY"
  },
  {
    name: "BANK"
  },
  {
    name: "BAR"
  },
  {
    name: "BEAUTY_SALON"
  },
  {
    name: "BICYCLE_STORE"
  },
  {
    name: "BOOK_STORE"
  },
  {
    name: "BOWLING_ALLEY"
  },
  {
    name: "BUS_STATION"
  },
  {
    name: "CAFE"
  },
  {
    name: "CAMPGROUND"
  },
  {
    name: "CAR_DEALER"
  },
  {
    name: "CAR_RENTAL"
  },
  {
    name: "CAR_REPAIR"
  },
  {
    name: "CAR_WASH"
  },
  {
    name: "CASINO"
  },
  {
    name: "CEMETERY"
  },
  {
    name: "CHURCH"
  },
  {
    name: "CITY_HALL"
  },
  {
    name: "CLOTHING_STORE"
  },
  {
    name: "CONVENIENCE_STORE"
  },
  {
    name: "COURTHOUSE"
  },
  {
    name: "DENTIST"
  },
  {
    name: "DEPARTMENT_STORE"
  },
  {
    name: "DOCTOR"
  },
  {
    name: "DRUGSTORE"
  },
  {
    name: "ELECTRICIAN"
  },
  {
    name: "ELECTRONICS_STORE"
  },
  {
    name: "EMBASSY"
  },
  {
    name: "FIRE_STATION"
  },
  {
    name: "FLORIST"
  },
  {
    name: "FUNERAL_HOME"
  },
  {
    name: "FURNITURE_STORE"
  },
  {
    name: "GAS_STATION"
  },
  {
    name: "GYM"
  },
  {
    name: "HAIR_CARE"
  },
  {
    name: "HARDWARE_STORE"
  },
  {
    name: "HINDU_TEMPLE"
  },
  {
    name: "HOME_GOODS_STORE"
  },
  {
    name: "HOSPITAL"
  },
  {
    name: "INSURANCE_AGENCY"
  },
  {
    name: "JEWELRY_STORE"
  },
  {
    name: "LAUNDRY"
  },
  {
    name: "LAWYER"
  },
  {
    name: "LIBRARY"
  },
  {
    name: "LIGHT_RAIL_STATION"
  },
  {
    name: "LIQUOR_STORE"
  },
  {
    name: "LOCAL_GOVERNMENT_OFFICE"
  },
  {
    name: "LOCKSMITH"
  },
  {
    name: "LODGING"
  },
  {
    name: "MEAL_DELIVERY"
  },
  {
    name: "MEAL_TAKEAWAY"
  },
  {
    name: "MOSQUE"
  },
  {
    name: "MOVIE_RENTAL"
  },
  {
    name: "MOVIE_THEATER"
  },
  {
    name: "MOVING_COMPANY"
  },
  {
    name: "MUSEUM"
  },
  {
    name: "NIGHT_CLUB"
  },
  {
    name: "PAINTER"
  },
  {
    name: "PARK"
  },
  {
    name: "PARKING"
  },
  {
    name: "PET_STORE"
  },
  {
    name: "PHARMACY"
  },
  {
    name: "PHYSIOTHERAPIST"
  },
  {
    name: "PLUMBER"
  },
  {
    name: "POLICE"
  },
  {
    name: "POST_OFFICE"
  },
  {
    name: "PRIMARY_SCHOOL"
  },
  {
    name: "REAL_ESTATE_AGENCY"
  },
  {
    name: "RESTAURANT"
  },
  {
    name: "ROOFING_CONTRACTOR"
  },
  {
    name: "RV_PARK"
  },
  {
    name: "SCHOOL"
  },
  {
    name: "SECONDARY_SCHOOL"
  },
  {
    name: "SHOE_STORE"
  },
  {
    name: "SHOPPING_MALL"
  },
  {
    name: "SPA"
  },
  {
    name: "STADIUM"
  },
  {
    name: "STORAGE"
  },
  {
    name: "STORE"
  },
  {
    name: "SUBWAY_STATION"
  },
  {
    name: "SUPERMARKET"
  },
  {
    name: "SYNAGOGUE"
  },
  {
    name: "TAXI_STAND"
  },
  {
    name: "TOURIST_ATTRACTION"
  },
  {
    name: "TRAIN_STATION"
  },
  {
    name: "TRANSIT_STATION"
  },
  {
    name: "TRAVEL_AGENCY"
  },
  {
    name: "UNIVERSITY"
  },
  {
    name: "VETERINARY_CARE"
  },
  {
    name: "ZOO"
  }
]
